import { ChnAuth, ChnLayoutPage } from '@chainless/chainless-react'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom'
import Auditlog from './components/Auditlog'
import Bestellingen from './components/Bestellingen'
import Producten from './components/Producten'
import ProjectDetails from './components/ProjectDetails'
import Projecten from './components/Projecten'
import './index.css'
import reportWebVitals from './reportWebVitals'

const tabs = [
  { name: 'Projecten', route: '/projecten' },
  { name: 'Producten', route: '/producten' },
  { name: 'Bestellingen', route: '/bestellingen' },
  { name: 'Auditlog', route: '/auditlog' }
]

const router = createBrowserRouter([
  {
    path: '/',
    element: <ChnLayoutPage tabs={tabs} name="ChaMBeR" />,
    children: [
      {
        path: '/',
        element: <Navigate to="/projecten" replace={true} />
      },
      {
        path: '/projecten',
        element: <Projecten />,
        children: [
          { path: ':projCode', element: <ProjectDetails /> }
        ]
      },
      {
        path: '/producten',
        element: <Producten />,
        children: [
          { path: ':prodId', element: <Producten /> }
        ]
      },
      {
        path: '/bestellingen',
        element: <Bestellingen />,
        children: [
          { path: ':orderId', element: <Bestellingen /> },
          { path: 'create', element: <Bestellingen /> }
        ]
      },
      { path: '/auditlog', element: <Auditlog /> }
    ]
  }
])

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <ChnAuth>
      <RouterProvider router={router} />
    </ChnAuth>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
