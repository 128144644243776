import React from 'react'
import { FaNotEqual, FaWarehouse } from 'react-icons/fa'
import chainlessLogo from './../assets/chainless2.png'
import vumc from './../assets/vumc.png'
import zuid from './../assets/soetelieve.png'

export const ProdUpper = ({ prodNameChanger, sorted, setSorted }) => {
  return (
    <div className='upper'>
      <input onChange={prodNameChanger} placeholder='Zoeken...' type='text' />
      <div className='legenda'>
        <div className='legendaGroup'>
          <button onClick={() => sorted !== 'dif' ? setSorted('dif') : setSorted('abc')}><FaNotEqual title='Verschil' style={{ color: '#9c9c9c' }} className='legendaIcon' /></button>
          <button onClick={() => sorted !== 'ass' ? setSorted('ass') : setSorted('abc')} title='Assen'><img src={chainlessLogo} alt='Assen' className='legendaImage' /></button>
          <button onClick={() => sorted !== 'zui' ? setSorted('zui') : setSorted('abc')} title='Zuid'><img src={zuid} alt='Zuid' className='legendaImage' /></button>
          <button onClick={() => sorted !== 'vum' ? setSorted('vum') : setSorted('abc')} title='VUmc'><img src={vumc} alt='VuMC' className='legendaImage' /></button>
          <button onClick={() => sorted !== 'tot' ? setSorted('tot') : setSorted('abc')}><FaWarehouse title='Totaal' style={{ color: '#006adf' }} className='legendaIcon' /></button>
        </div>
      </div>
    </div>
  )
}
