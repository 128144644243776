import { ChnRoleCheck, useFetch } from '@chainless/chainless-react'
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js'
import QRCode from 'qrcode'
import React, { useEffect, useState } from 'react'
import { Pie } from 'react-chartjs-2'
import { FaArrowAltCircleRight, FaDownload, FaPencilAlt } from 'react-icons/fa'
import { DeleteProd } from './DeleteProd'
import './ProductDetails.css'
import { UpdateLocation } from './UpdateLocation'
import { BestellingenOnderProduct, ReserveringenOnderProduct } from './Lists'

ChartJS.register(ArcElement, Tooltip, Legend)

const ProductDetails = ({ prodId, refresh }) => {
  const { data: prodLocationAmounts, fetchData: prodLocFetch } = useFetch(`/api/product_amounts?id=${prodId}`)
  const { data: reservOfProds, fetchData: reservOfProdsFetch } = useFetch(`/api/controller_reservations?product_id=${prodId}`)
  const { data: prodOrders, fetchData: prodOrderFetch } = useFetch(`/api/orders_of_product?product_id=${prodId}`)
  const { data: patchProdRes, error: patchProdError, fetchData: patchProd } = useFetch(`/api/product/${prodId}`, { method: 'PATCH', lateFetch: true })
  const [updatingProduct, setUpdatingProduct] = useState(false)
  const [updateLocation, setUpdateLocation] = useState(false)
  const [newProdName, setNewProdName] = useState('')
  const [newNotes, setNewNotes] = useState('')
  const [newMinAmount, setNewMinAmount] = useState()
  const [assenAmount, setAssenAmount] = useState()
  const [zuidAmount, setZuidAmount] = useState()
  const [vumcAmount, setVumcAmount] = useState()
  const [qr, setQr] = useState('')
  const [items, setItems] = useState()

  const url = 'ChMbR - ' + prodId

  const refreshAll = () => {
    setUpdateLocation(false)
    setUpdatingProduct(false)
    refresh()
    prodLocFetch()
    reservOfProdsFetch()
    prodOrderFetch()
  }

  useEffect(() => {
    if (prodLocationAmounts) {
      setNewMinAmount(prodLocationAmounts[0].min_amount_stock)
      setNewProdName(prodLocationAmounts[0].name)
      setNewNotes(prodLocationAmounts[0].notes)
      setAssenAmount(prodLocationAmounts[0].assen)
      setZuidAmount(prodLocationAmounts[0].zuid)
      setVumcAmount(prodLocationAmounts[0].vumc)
    }
  }, [prodLocationAmounts])

  useEffect(() => {
    if (prodId) {
      QRCode.toDataURL(url, {
        width: 190,
        margin: 2,
        color: {
          dark: '#000000',
          light: '#EEEEEE00'
        }
      }, (err, url) => {
        if (err) return console.error(err)
        setQr(url)
      })
    }
  }, [qr, prodId, url])

  const updateProduct = () => {
    if (
      newProdName !== prodLocationAmounts[0].name ||
      newNotes !== prodLocationAmounts[0].notes ||
      newMinAmount !== prodLocationAmounts[0].min_amount_stock ||
      assenAmount !== prodLocationAmounts[0].assen ||
      zuidAmount !== prodLocationAmounts[0].zuid ||
      vumcAmount !== prodLocationAmounts[0].vumc
    ) {
      patchProd(JSON.stringify({
        productDTO: {
          name: newProdName,
          notes: newNotes,
          minAmountStock: newMinAmount
        },
        productAmountsDTO: {
          assen: assenAmount,
          zuid: zuidAmount,
          vumc: vumcAmount
        }
      }))
    }
  }

  useEffect(() => {
    if (patchProdRes) {
      refreshAll()
      setNewMinAmount(undefined)
    } else if (patchProdError) {
      console.error('Fout bij het bijwerken van product', patchProdError)
    }
  }, [patchProdRes, patchProdError])

  useEffect(() => {
    setUpdatingProduct(false)
    setNewMinAmount(undefined)
    setNewProdName(undefined)
    setNewNotes(undefined)
    setAssenAmount(undefined)
    setZuidAmount(undefined)
    setVumcAmount(undefined)
  }, [prodId])

  useEffect(() => {
    if (prodLocationAmounts) {
      setItems({
        datasets:
          [
            {
              label: 'Aantal',
              data: [prodLocationAmounts[0].difference, prodLocationAmounts[0].totalOrderAmount, prodLocationAmounts[0].totalResAmount],
              backgroundColor: [
                prodLocationAmounts[0].difference < 0 ? '#bd290d' : '#9c9c9c',
                '#00a0bf',
                '#485074'
              ],
              borderColor: [
                '#505050'
              ],
              borderWidth: 1
            }
          ]
      })
    }
  }, [prodLocationAmounts])

  return (
    <>
      {prodLocationAmounts &&
        <div className='details'>
          {!updatingProduct && !updateLocation &&
            <>
              <h1>{prodLocationAmounts[0].name}</h1>
              <p>{prodLocationAmounts[0].notes}</p>
              <div className='updateAmount'>
                <ChnRoleCheck allowedRoles={['chn.magazijn_beheer', 'chn.dev']}>
                  <div className='updateButtons'>
                    <button onClick={() => updatingProduct ? setUpdatingProduct(false) : setUpdatingProduct(true)}><FaPencilAlt fontSize={20} color={'black'} /></button>
                    <button onClick={() => updateLocation ? setUpdateLocation(false) : setUpdateLocation(true)}><FaArrowAltCircleRight fontSize={20} color={'black'} /></button>
                  </div>
                </ChnRoleCheck>
              </div>
              <div className='updateAmount'>
                <div className='updateAmountItem'>
                  <div className='updateAmountText'>
                    <span>Totale voorraad</span>
                  </div>
                  <div className='updateAmountButton'>
                    <span>{prodLocationAmounts[0].assen + prodLocationAmounts[0].zuid + prodLocationAmounts[0].vumc}</span>
                  </div>
                </div>
                <div className='updateAmountItem'>
                  <div className='updateAmountText'>
                    <span>Minimale stock</span>
                  </div>
                  <div className='updateAmountButton'>
                    <span>{prodLocationAmounts[0].min_amount_stock}</span>
                  </div>
                </div>
                <div className='updateAmountItem'>
                  <div className='updateAmountText'>
                    <span>Voorraad Assen</span>
                  </div>
                  <div className='updateAmountButton'>
                    <span>{prodLocationAmounts[0].assen}</span>
                  </div>
                </div>
                <div className='updateAmountItem'>
                  <div className='updateAmountText'>
                    <span>Voorraad Zuid</span>
                  </div>
                  <div className='updateAmountButton'>
                    <span>{prodLocationAmounts[0].zuid}</span>
                  </div>
                </div>
                <div className='updateAmountItem'>
                  <div className='updateAmountText'>
                    <span>Voorraad VUmc</span>
                  </div>
                  <div className='updateAmountButton'>
                    <span>{prodLocationAmounts[0].vumc}</span>
                  </div>
                </div>
                <div className='updateAmountItem'>
                  <div className='updateAmountText'>
                    <span>Totaal aan reserveringen</span>
                  </div>
                  <div className='updateAmountButton'>
                    <span>{prodLocationAmounts[0].totalResAmount}</span>
                  </div>
                </div>
                <div className='updateAmountItem'>
                  <div className='updateAmountText'>
                    <span>Totaal in bestelling</span>
                  </div>
                  <div className='updateAmountButton'>
                    <span>{prodLocationAmounts[0].totalOrderAmount}</span>
                  </div>
                </div>
              </div>
            </>
          }
          {updateLocation &&
            <>
              {prodLocationAmounts &&
                <UpdateLocation prodLocationAmounts={prodLocationAmounts} prodId={prodId} refresh={refreshAll} />
              }
            </>
          }
          {updatingProduct &&
            <>
              <div className='updateAmount'>
                <DeleteProd prodLocationAmounts={prodLocationAmounts} prodId={prodId} refresh={refreshAll} />
              </div>
              <div className='updatingProduct'>
                <div className='setter'>
                  <p>Product naam</p>
                  <input
                    required
                    type='text'
                    value={newProdName}
                    onChange={event => setNewProdName(event.target.value)}
                  />
                </div>
                <div className='setter'>
                  <p>Product beschrijving</p>
                  <input
                    required
                    type='text'
                    value={newNotes}
                    onChange={event => setNewNotes(event.target.value)}
                  />
                </div>
                <div className='setter'>
                  <p>Minimale stock</p>
                  <input
                    required
                    min={0}
                    type='number'
                    value={newMinAmount}
                    onChange={event => setNewMinAmount(event.target.value)}
                  />
                </div>
                <div className='setter'>
                  <p>Voorraad Assen</p>
                  <input
                    required
                    min={0}
                    type='number'
                    value={assenAmount}
                    onChange={event => setAssenAmount(event.target.value)}
                  />
                </div>
                <div className='setter'>
                  <p>Voorraad Zuid</p>
                  <input
                    required
                    min={0}
                    type='number'
                    value={zuidAmount}
                    onChange={event => setZuidAmount(event.target.value)}
                  />
                </div>
                <div className='setter'>
                  <p>Voorraad VUmc</p>
                  <input
                    required
                    min={0}
                    type='number'
                    value={vumcAmount}
                    onChange={event => setVumcAmount(event.target.value)}
                  />
                </div>
                <button
                  style={{ width: 70, height: 35, borderRadius: 10 }}
                  onClick={() => updateProduct()}
                >
                  Set
                </button>
              </div>
            </>
          }
        </div>
      }
      <div className='chartAndQrContent'>
        <div className='chartContent'>
          <div className='pie'>
            {items &&
              <Pie data={items} />
            }
          </div>
        </div>
        <div className='qr'>
          {qr &&
            <>
              <img src={qr} alt='qr' />
              <a href={qr} download="qrcode.png"><FaDownload /></a>
            </>
          }
        </div>
      </div>
      <div className='prodItems'>
        <div className='prodReservations'>
          {reservOfProds &&
            <>
              <h2 style={{ marginBottom: 20 }}>Reserveringen</h2>
              <ReserveringenOnderProduct reservations={reservOfProds} />
            </>
          }
        </div>
        <div className='orders'>
          {prodOrders &&
            <>
              <h2 style={{ color: '#00a0bf', marginBottom: 20 }}>Bestellingen</h2>
              <BestellingenOnderProduct orders={prodOrders} />
            </>
          }
        </div>
      </div>
    </>
  )
}

export default ProductDetails
