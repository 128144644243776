import { ChnRoleCheck, useFetch } from '@chainless/chainless-react'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import CreateProd from './CreateProd'
import { ProductsList } from './Lists'
import { ProdUpper } from './ProdUpper'
import ProductDetails from './ProductDetails'
import './Producten.css'

const Producten = () => {
  const { prodId } = useParams()
  const { data: prodLocationAmounts, fetchData: refresh } = useFetch('/api/product_amounts')
  const [filterText, setFilterText] = useState('')
  const [disabled, setDisabled] = useState(false)
  const [addProduct, setAddProduct] = useState(false)
  const [sortedDifference, setSortedDifference] = useState('abc')
  const [completeProducts, setCompleteProducts] = useState([])

  useEffect(() => {
    if (prodLocationAmounts) {
      setCompleteProducts(prodLocationAmounts)
    }
  }, [prodLocationAmounts])

  const prodNameChanger = (e) => {
    const lowerCase = e.target.value.toLowerCase()
    setFilterText(lowerCase)
  }

  const productAdded = () => {
    refresh()
    setAddProduct(false)
  }

  const sortCompleteProducts = () => {
    setCompleteProducts((completeProducts) => {
      if (completeProducts && completeProducts.length === 0) {
        return []
      }

      const sorted = [...completeProducts]

      if (sortedDifference === 'dif') {
        sorted.sort((a, b) => {
          if (a.difference < 0 && b.difference >= 0) {
            return -1
          } else if (a.difference >= 0 && b.difference < 0) {
            return 1
          } else if (a.difference === 0 && a.min_amount_stock > 0) {
            return -1
          } else if (b.difference === 0 && b.min_amount_stock > 0) {
            return 1
          }
          return a.difference - b.difference
        })
      } else if (sortedDifference === 'abc') {
        sorted.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
      } else if (sortedDifference === 'res') {
        sorted.sort((a, b) => b.totalResAmount - a.totalResAmount)
      } else if (sortedDifference === 'bes') {
        sorted.sort((a, b) => b.totalOrderAmount - a.totalOrderAmount)
      } else if (sortedDifference === 'tot') {
        sorted.sort((a, b) => (b.assen + b.zuid + b.vumc) - (a.assen + a.zuid + a.vumc))
      } else if (sortedDifference === 'ass') {
        sorted.sort((a, b) => b.assen - a.assen)
      } else if (sortedDifference === 'zui') {
        sorted.sort((a, b) => b.zuid - a.zuid)
      } else if (sortedDifference === 'vum') {
        sorted.sort((a, b) => b.vumc - a.vumc)
      }

      return sorted
    })
  }

  // sorteer knoppen
  useEffect(() => {
    sortCompleteProducts()
  }, [sortedDifference])

  useEffect(() => {
    setDisabled(!!addProduct)
  }, [addProduct])

  return (
    <>
      <div className='productenlijst'>
        <h1>Alle producten</h1>
        <ChnRoleCheck allowedRoles={['chn.magazijn_beheer', 'chn.dev']}>
          <button disabled={disabled} style={{ backgroundColor: disabled ? 'grey' : '#485074' }} className='addButton' onClick={() => setAddProduct(!addProduct)}>Product toevoegen</button>
        </ChnRoleCheck>
        <>
          {!addProduct
            ? <ProdUpper prodNameChanger={prodNameChanger} sorted={sortedDifference} setSorted={setSortedDifference} addProduct={addProduct} />
            : <CreateProd productAdded={productAdded} setAddProduct={setAddProduct} />
          }
        </>
        {completeProducts &&
          <div className='items'>
            <ProductsList completeProd={completeProducts} filterText={filterText} />
          </div>
        }
      </div>
      <div className='productDetails'>
        {prodId && <ProductDetails prodId={prodId} refresh={refresh} />}
      </div>
    </>
  )
}

export default Producten
