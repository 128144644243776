import React, { useEffect, useRef, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import './Lists.css'
import { useFetch } from '@chainless/chainless-react'
import { FaPencilAlt, FaSave } from 'react-icons/fa'
import { DifferenceResAmount } from './Amounts'
import { DeleteReservation } from './DeleteReservation'

export const ProductsList = ({ completeProd, filterText }) => {
  const [filteredProducts, setFilteredProducts] = useState([])
  const scrollRef = useRef(null)
  const location = useLocation()

  useEffect(() => {
    if (filterText) {
      setFilteredProducts(completeProd.filter((el) => el.name.toLowerCase().includes(filterText)))
    } else {
      setFilteredProducts(completeProd)
    }
  }, [completeProd, filterText])

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const scrollIntoView = queryParams.get('scrollIntoView')

    if (scrollIntoView === 'true' && scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [location.search, filteredProducts])

  return (
    <>
      {filteredProducts.map(prod => (
        <div className='listItem' key={prod.id} ref={location.pathname === `/producten/${prod.id}` ? scrollRef : null}
          style={{ backgroundColor: (prod.difference < 0 ? 'rgb(189 41 13 / 60%)' : prod.min_amount_stock > 0 && prod.difference === 0 ? 'rgba(255, 162, 0, 0.60)' : 'white') }}>
          <NavLink to={`/producten/${prod.id}`} className={({ isActive }) => (isActive ? 'actlink' : 'notActLink')}>
            <h2>{prod.name}</h2>
            <div className='numbers'>
              <div className='numberGroup'>
                <p style={{ color: (prod.difference < 0 ? '#bd290d' : prod.min_amount_stock > 0 && prod.difference === 0 ? 'orange' : 'white'), backgroundColor: '#9c9c9c' }}>{prod.difference}</p>
                <p style={{ backgroundColor: '#485074' }}>{prod.assen}</p>
                <p style={{ backgroundColor: '#019934' }}>{prod.zuid}</p>
                <p style={{ backgroundColor: '#f07814' }}>{prod.vumc}</p>
                <p style={{ backgroundColor: '#006adf' }}>{prod.assen + prod.zuid + prod.vumc}</p>
              </div>
            </div>
          </NavLink>
        </div>
      ))}
    </>
  )
}

export const BestellingenOnderProduct = ({ orders }) => {
  return (
    <>
      {orders.map(order => (
        <div className='underProduct' key={order.orderProject.order.id}>
          <NavLink to={`/bestellingen/${order.orderProject.order.id}?scrollIntoView=true`} className='link'>
            <h2 style={{ color: '#00a0bf' }}>{order.orderProject.order.name}</h2>
            <h2 style={{ color: '#00a0bf' }}>{order.amount}</h2>
          </NavLink>
        </div>
      ))}
    </>
  )
}

export const ReserveringenOnderProduct = ({ reservations }) => {
  return (
    <>
      {reservations.map(res => (
        res.amount !== 0
          ? <div className='underProduct' key={res.id}>
            <NavLink to={`/projecten/${res.project.code}?scrollIntoView=true`} className='link'>
              <h2>{res.project.fullName || res.project.code}</h2>
              <h2>{res.amount}</h2>
            </NavLink>
          </div>
          : ''
      ))}
    </>
  )
}

export const ProjectenLijst = ({ projects }) => {
  const scrollRef = useRef(null)
  const location = useLocation()

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const scrollIntoView = queryParams.get('scrollIntoView')

    if (scrollIntoView === 'true' && scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [location.search])

  return (
    <>
      {projects.map(proj => (
        <div className='listItem' key={proj.code} ref={location.pathname === `/projecten/${proj.code}` ? scrollRef : null}>
          <NavLink to={`/projecten/${proj.code}`} className={({ isActive }) => (isActive ? 'actlink' : 'notActLink')}>
            <h2>{proj.fullName}</h2>
            <h2>{proj.reservationCount > 0 ? proj.reservationCount : undefined}</h2>
          </NavLink>
        </div>
      ))}
    </>
  )
}

export const ReserveringenOnderProjecten = ({ reservations, reloadResevations, projCode }) => {
  const { data: productsData } = useFetch('/api/product')
  const [newProduct, setNewProduct] = useState('')
  const [newAmount, setNewAmount] = useState(1)
  const [selectedReservation, setselectedReservation] = useState()
  const [number, setNumber] = useState()
  const [create, setCreate] = useState(false)
  const [products, setProducts] = useState([])
  const { res: patchContrResRes, fetchData: patchContrRes, error: patchContrResError } = useFetch('/api/controller_reservations', { method: 'PATCH', lateFetch: true })
  const { res: postContrResRes, fetchData: postContrRes, error: postContrResError } = useFetch('/api/controller_reservations', { method: 'POST', lateFetch: true })

  useEffect(() => {
    setselectedReservation()
    setNumber(undefined)
    setCreate(false)
  }, [reservations])

  useEffect(() => {
    if (productsData) {
      setProducts(productsData.sort((a, b) => a.name.localeCompare(b.name)))
    }
  }, [productsData])

  const saveAmount = async (number, resId, resAmount) => {
    if (number !== undefined && resId !== undefined && resAmount - number >= 0) {
      patchContrRes(JSON.stringify({ id: resId, amount: number }))
    } else if (resAmount - number < 0) {
      alert('kan niet lager dan 0 gezet worden')
    } else {
      setselectedReservation()
    }
  }

  useEffect(() => {
    if (patchContrResRes && patchContrResRes.ok) {
      reloadResevations()
      setselectedReservation()
    } else if (patchContrResError) {
      console.error('Fout bij het bijwerken van reservering', patchContrResError)
    }
  }, [patchContrResError, patchContrResRes])

  const createRes = async () => {
    if (newAmount >= 0 && newProduct !== undefined && newAmount !== undefined && projCode) {
      const productId = products.find(p => p.name === newProduct)?.id
      const resProduct = reservations.find(r => r.product.id === productId)
      if (resProduct || !productId) {
        alert('dit product staat al in de lijst of is niet gevonden...')
        setNewProduct()
        reloadResevations()
      } else {
        postContrRes(JSON.stringify({
          productId,
          projectCode: projCode,
          amount: parseInt(newAmount),
          originalamount: parseInt(newAmount)
        }))
      }
    }
  }

  useEffect(() => {
    if (postContrResRes && postContrResRes.ok) {
      reloadResevations()
      setNewProduct()
      setCreate(false)
      setNewAmount(1)
    } else if (postContrResError) {
      console.error('Fout bij het aanmaken van een reservering', postContrResError)
    }
  }, [postContrResRes, postContrResError])

  return (
    <>
      {projCode !== 'old_reservations'
        ? <div>
          {create && products.length &&
            <div className='aanmakenRes'>
              <input type="text" list="products-list" onChange={(e) => setNewProduct(e.target.value)} value={newProduct} placeholder="Selecteer product" style={{ width: '50%' }} />
              <datalist id="products-list">
                {products.map(prod => (
                  <option key={prod.id}>{prod.name}</option>
                ))}
              </datalist>
              <input type='number' min={1} onChange={(e) => setNewAmount(e.target.value)} value={newAmount} />
            </div>
          }
          <div className='createButtons'>
            {!create &&
              <button onClick={() => create ? setCreate(false) : setCreate(true)}>Reservering aanmaken</button>
            }
            {create &&
              <button onClick={() => createRes()}>Toevoegen</button>
            }
          </div>
          {reservations &&
            reservations.map(res => (
              <div key={res.id}>
                <div className='listItem'>
                  <div className='notActLink'>
                    <NavLink to={`/producten/${res.product.id}?scrollIntoView=true`} className={'resLink'}>
                      <h2 style={{ marginLeft: 20 }}>{res.product.name}</h2>
                    </NavLink>
                    <div className='resAmounts'>
                      <div>
                        <DifferenceResAmount prodId={res.product.id} />
                      </div>
                      {res.id !== selectedReservation
                        ? <div className='resNumbers'>
                          <h2 title='Gereserveerd'>{res.amount}</h2>
                          <h2 style={{ color: '#c2c2c2' }}>||</h2>
                          <h2 title='Originele reservering' style={{ color: '#c2c2c2' }}>{res.originalamount}</h2>
                        </div>
                        : <input type='number' min={0} defaultValue={res.amount} onChange={(e) => setNumber(res.amount - e.target.value)} />
                      }
                    </div>
                  </div>
                  {res.id !== selectedReservation
                    ? <>
                      <button onClick={() => setselectedReservation(res.id)}><FaPencilAlt style={{ fontSize: 16 }} /></button>
                      <DeleteReservation reloadResevations={reloadResevations} setselectedReservation={setselectedReservation} resId={res.id} />
                    </>
                    : <button onClick={() => saveAmount(number, res.id, res.amount)}><FaSave style={{ fontSize: 16 }} /></button>
                  }
                </div>
              </div>
            ))
          }
        </div>
        : <>
          {reservations &&
            reservations.map(res => (
              <div key={res.id}>
                <div className='listItem'>
                  <div className='notActLink'>
                    <h2>{res.projectCode} || {res.product.name}</h2>
                    <div className='resAmounts'>
                      <h2>{res.amount}</h2>
                      <DeleteReservation reloadResevations={reloadResevations} setselectedReservation={setselectedReservation} resId={res.id} />
                    </div>
                  </div>
                </div>
              </div>
            ))
          }
        </>
      }
    </>
  )
}

export const BestellingenOnderProject = ({ orders }) => {
  return (
    <>
      {orders.map(order => (
        <div className='listItem' key={order.order.id}>
          <NavLink to={`/bestellingen/${order.order.id}?scrollIntoView=true`} className='notActLink' style={{ justifyContent: 'center' }}>
            <div>
              <h2 style={{ color: '#00a0bf' }}>{order.order.name}</h2>
            </div>
          </NavLink>
        </div>
      ))}
    </>
  )
}

export const BestellingenLijst = ({ orders }) => {
  const scrollRef = useRef(null)
  const location = useLocation()

  const formatDate = (dateString) => {
    const year = dateString.slice(0, 4)
    const month = dateString.slice(5, 7)
    const day = dateString.slice(8, 10)

    return `${day}-${month}-${year}`
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const scrollIntoView = queryParams.get('scrollIntoView')

    if (scrollIntoView === 'true' && scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [location.search])

  return (
    <>
      {orders.sort((a, b) => b.id - a.id).map(order => (
        <div className='listItem best' key={order.id} style={{ height: 75 }} ref={location.pathname === `/projecten/${order.id}` ? scrollRef : null}>
          <NavLink to={`/bestellingen/${order.id}`} className={({ isActive }) => (isActive ? 'actlink best' : 'notActLink best')}>
            <h2 style={{ color: '#00a0bf' }}>{order.name}</h2>
            <h2 style={{ color: 'grey' }}>{formatDate(order.orderDate)}</h2>
          </NavLink>
        </div>
      ))}
    </>
  )
}

export const BestellingenInhoudLijst = ({ order }) => {
  return (
    <>
      {order.orderProjects.map(proj => (
        <div key={proj.id}>
          <h2 style={{ color: 'black' }}>{proj.project}</h2>
          {proj.orderProjectProducts.map(prod => (
            <div className='listItem' key={prod.id}>
              <div className='notActLink'>
                <h2 style={{ color: '#00a0bf' }}>{prod.name}</h2>
                <div className='amounts'>
                  <h2 style={{ color: '#00a0bf' }}>{prod.amount}</h2>
                </div>
              </div>
            </div>
          ))}
        </div>
      ))}
    </>
  )
}
