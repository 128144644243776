import React from 'react'
import { useFetch } from '@chainless/chainless-react'

export const AddShortages = ({ proj, addOrderItem }) => {
  if (proj) {
    const { data: productsOfProj } = useFetch(`/api/controller_reservations?projectCode=${proj.code}`)
    const { data: allProds } = useFetch('/api/product_amounts')

    const addAllShortages = () => {
      for (const res of productsOfProj) {
        const selectedProd = allProds.find(prod => prod.id === res.product.id)
        if (selectedProd.difference < 0 && selectedProd.difference <= res.amount) {
          const newprodAmount = Math.min(-selectedProd.difference, res.amount)
          addOrderItem(res.product.name, res.project.fullName, newprodAmount)
        }
      }
    }
    return (
      <button onClick={() => addAllShortages()} style={{ marginLeft: 10 }}>Alle tekorten</button>
    )
  }
}
