import { RES_TYPES, useFetch } from '@chainless/chainless-react'
import React, { useEffect, useState } from 'react'
import { FaArrowAltCircleRight } from 'react-icons/fa'

export const UpdateLocation = ({ prodLocationAmounts, prodId, refresh }) => {
  const [locationA, setLocationA] = useState('1')
  const [locationB, setLocationB] = useState('2')
  const [locationTransferAmount, setLocationTransferAmount] = useState(0)
  const { data, error, loading, fetchData } = useFetch(`/api/update_location_amount/${prodId}`, { method: 'PATCH', resType: RES_TYPES.text, lateFetch: true })

  const transferLocationAmount = () => {
    if (locationA === '1' && prodLocationAmounts[0].assen >= locationTransferAmount) {
      transferingLocationAmount()
    } else if (locationA === '2' && prodLocationAmounts[0].zuid >= locationTransferAmount) {
      transferingLocationAmount()
    } else if (locationA === '3' && prodLocationAmounts[0].vumc >= locationTransferAmount) {
      transferingLocationAmount()
    } else {
      alert('Kan niet meer overplaatsen dan op voorraad')
    }
  }

  const transferingLocationAmount = async () => {
    fetchData(JSON.stringify({
      amount: parseInt(locationTransferAmount),
      location1_id: locationA,
      location2_id: locationB,
      product_id: prodId
    }))
  }

  useEffect(() => {
    if (data === 'Location transfer succesvol') {
      refresh()
      setLocationA('1')
      setLocationB('2')
      setLocationTransferAmount(0)
    } else if (error) {
      console.error('Fout bij het transferen van product', error)
    }
  }, [data, error])

  return (
    <div className='updatingProduct'>
      <div className='updatingLocation'>
        <select
          value={locationA}
          onChange={event => setLocationA(event.target.value)}
        >
          <option value='1'>Assen</option>
          <option value='2'>Zuid</option>
          <option value='3'>vumc</option>
        </select>
        <p><FaArrowAltCircleRight fontSize={20} color={'black'} /></p>
        <select
          value={locationB}
          onChange={event => setLocationB(event.target.value)}
        >
          <option value='1'>Assen</option>
          <option value='2'>Zuid</option>
          <option value='3'>vumc</option>
        </select>
      </div>
      <div className='updatingLocation'>
        <input
          required
          type='number'
          value={locationTransferAmount}
          onChange={event => setLocationTransferAmount(event.target.value)}
        />
      </div>
      { !loading && <button
        style={{ width: 70, height: 35, borderRadius: 10 }}
        onClick={() => transferLocationAmount()}
      >
        Set
      </button> }
    </div>
  )
}
