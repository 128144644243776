import { ChnRoleCheck, useFetch } from '@chainless/chainless-react'
import React, { useEffect, useState } from 'react'
import { FaCartPlus } from 'react-icons/fa'
import { NavLink, useLocation, useParams } from 'react-router-dom'
import './Bestellingen.css'
import BestellingenDetails from './BestellingenDetails'
import { CreateBest } from './CreateBest'
import { BestellingenLijst } from './Lists'

const Bestellingen = () => {
  const location = useLocation()
  const { orderId } = useParams()
  const { data: orders, fetchData: refresh } = useFetch('/api/order')
  const [inputText, setInputText] = useState('')
  const [bestellingen, setBestellingen] = useState([])

  const inputHandler = (e) => {
    const lowerCase = e.target.value.toLowerCase()
    setInputText(lowerCase)
  }

  useEffect(() => {
    if (orders?._embedded.order) {
      setBestellingen(orders._embedded.order
        .filter((el) => inputText === '' || el.name.toLowerCase().includes(inputText))
        .sort((a, b) => a.name.localeCompare(b.name))
      )
    }
  }, [orders, inputText])

  return (
    <>
      <div className='bestellingenlijst'>
        <h1>Lopende Bestellingen</h1>
        <div className='upper'>
          <input onChange={inputHandler} placeholder='Zoeken...' type='text' />
          <div className='legenda'>
            <ChnRoleCheck allowedRoles={['chn.magazijn_beheer', 'chn.dev']} >
              <NavLink title='Bestelling aanmaken' to={'/bestellingen/create'}>
                <FaCartPlus style={{ fontSize: 28, color: '#00a0bf' }} />
              </NavLink>
            </ChnRoleCheck>
          </div>
        </div>
        {orders &&
          <div className='items'>
            <BestellingenLijst orders={bestellingen} />
          </div>
        }
      </div>
      <div className='bestellingdetails'>
        {orderId && <BestellingenDetails orderId={orderId} refresh={refresh} />}
        {location.pathname.endsWith('create') && <CreateBest refresh={refresh} />}
      </div>
    </>
  )
}

export default Bestellingen
