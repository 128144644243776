import { ChnRoleCheck, useFetch } from '@chainless/chainless-react'
import React, { useEffect, useState } from 'react'
import { FaPencilAlt, FaTrashAlt } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import './BestellingenDetails.css'
import { BestellingenInhoudLijst } from './Lists'

const BestellingenDetails = ({ orderId, refresh }) => {
  const navigate = useNavigate()
  const { data: order, fetchData: refreshOrder } = useFetch(`/api/controller_order?order_id=${orderId}`)
  const { res: delOrderRes, error: delOrderError, fetchData: delOrder } = useFetch(`/api/controller_order?order_id=${orderId}`, { method: 'DELETE', lateFetch: true })
  const { res: patchOrderRes, error: patchOrderError, fetchData: patchOrder } = useFetch(`/api/update_order/${orderId}`, { method: 'PATCH', lateFetch: true })
  const [updateOrder, setUpdateOrder] = useState(false)
  const [orderName, setNewOrderName] = useState()
  const [orderInfo, setNewOrderInfo] = useState()

  useEffect(() => {
    if (order) {
      setNewOrderName(order.name)
      setNewOrderInfo(order.info)
    }
  }, [order])

  const deleteOrder = async () => {
    if (window.confirm('De complete bestelling verwijderen?')) {
      delOrder()
    }
  }

  useEffect(() => {
    if (delOrderRes && delOrderRes.ok) {
      navigate('/bestellingen')
      refresh()
    } else if (delOrderError && delOrderError) {
      console.error('Fout bij het verwijderen van de bestelling', delOrderError)
    }
  }, [delOrderRes, delOrderError])

  const updatingOrder = () => {
    patchOrder(
      JSON.stringify({
        name: orderName,
        info: orderInfo
      }))
  }

  useEffect(() => {
    if (patchOrderRes && patchOrderRes.ok) {
      refresh()
      refreshOrder()
      setUpdateOrder(false)
      setNewOrderName(undefined)
      setNewOrderInfo(undefined)
    } else if (patchOrderError && patchOrderError) {
      console.error('Fout bij het updaten van bestelling', patchOrderError)
    }
  }, [patchOrderRes, patchOrderError])

  if (order) {
    return (
      <>
        {!updateOrder &&
          <>
            <div className='bestDetails'>
              <h1 style={{ color: '#00a0bf' }}>{order.name}</h1>
              <ChnRoleCheck allowedRoles={['chn.magazijn_beheer', 'chn.dev']}>
                <div className='bestbuttons'>
                  <button onClick={() => deleteOrder()}><FaTrashAlt style={{ fontSize: 17, color: 'grey' }} /></button>
                  <button onClick={() => updateOrder ? setUpdateOrder(false) : setUpdateOrder(true)}><FaPencilAlt style={{ fontSize: 17, color: 'grey' }} /></button>
                </div>
              </ChnRoleCheck>
            </div>
            <div className='bestellingsInhoud'>
              <div className='infoItem'>
                <span>{order.info}</span>
              </div>
              <BestellingenInhoudLijst order={order} />
            </div>
          </>
        }
        {updateOrder &&
          <div className='updateForm'>
            <div className='setter'>
              <p>Naam van de bestelling:</p>
              <input
                required
                type='text'
                value={orderName}
                onChange={event => setNewOrderName(event.target.value)}
              />
            </div>
            <div className='setter'>
              <p>Extra informatie:</p>
              <input
                required
                type='text'
                value={orderInfo}
                onChange={event => setNewOrderInfo(event.target.value)}
              />
            </div>
            <button onClick={() => updatingOrder(order.id)}>Set</button>
          </div>
        }
      </>
    )
  }
}

export default BestellingenDetails
