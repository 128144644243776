import { useFetch } from '@chainless/chainless-react'
import React, { useEffect, useState } from 'react'

const CreateProd = ({ productAdded, setAddProduct }) => {
  const [newAmount, setNewAmount] = useState(0)
  const [product, setProduct] = useState()
  const [newNotes, setNewNotes] = useState('')
  const [location, setLocation] = useState(1)
  const { res: postProdRes, error: postProdError, fetchData: postProd } = useFetch(`/api/product/${location}`, { method: 'POST', lateFetch: true })

  const createProd = async () => {
    if (newAmount < 0 || !product) {
      console.error('Ongeldige waarde voor newAmount of ongedefinieerd product')
    } else {
      postProd(JSON.stringify({
        name: product,
        notes: newNotes,
        amount: parseInt(newAmount),
        minAmountStock: 0
      }))
    }
  }
  useEffect(() => {
    if (postProdRes && postProdRes.ok) {
      productAdded()
    } else if (postProdError) {
      console.error('Fout bij het creeeren van product', postProdError)
    }
  }, [postProdRes, postProdError])

  return (
    <>
      <div className='addProduct'>
        <button className='backButton' onClick={() => setAddProduct(false)}>Terug</button>
        <input className='prodSelect' type="text" onChange={(e) => setProduct(e.target.value)} placeholder="Product naam" />
        <input className='prodSelect' type="text" onChange={(e) => setNewNotes(e.target.value)} placeholder="Beschrijving" />
        <select name="locatie" id="locatie" onChange={(e) => setLocation(e.target.value)}>
          <option value={1}>Assen</option>
          <option value={2}>Zuid</option>
          <option value={3}>VUmc</option>
        </select>
        <input className='numSelect' type='number' min={0} onChange={(e) => setNewAmount(e.target.value)} value={newAmount} />
        <button className='backButton' style={{ backgroundColor: '#485074' }} onClick={createProd}>Toevoegen</button>
      </div>
    </>
  )
}

export default CreateProd
