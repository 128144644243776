import { useFetch } from '@chainless/chainless-react'
import React from 'react'
import { FaExclamationTriangle } from 'react-icons/fa'

export const DifferenceResAmount = ({ prodId }) => {
  const { data: prodAmounts } = useFetch(`/api/product_amounts?id=${prodId}`)

  if (prodAmounts) {
    return (
      <>
        {prodAmounts && prodAmounts[0].difference < 0
          ? <h2 title='Voorraad onvoldoende voor alle projecten'><FaExclamationTriangle style={{ color: '#e30303' }} /></h2>
          : <h2 title='In bestelling' style={{ color: '#00a0bf' }}>{prodAmounts[0].totalOrderAmount > 0 ? prodAmounts[0].totalOrderAmount : undefined}</h2>
        }
      </>
    )
  }
}
