import { useFetch } from '@chainless/chainless-react'
import React, { useEffect } from 'react'
import { FaTrashAlt } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

export const DeleteProd = ({ prodLocationAmounts, prodId, refresh }) => {
  const navigate = useNavigate()
  const { res: delRes, error: delError, fetchData: delProd } = useFetch(`/api/product/${prodId}`, { method: 'DELETE', lateFetch: true })

  const deleteProduct = async () => {
    if (prodLocationAmounts[0].totalResAmount === 0 && prodLocationAmounts[0].totalOrderAmount === 0 && prodLocationAmounts[0].difference === 0) {
      delProd()
    } else {
      alert('Product kan niet verwijderd worden omdat er nog reserveringen/bestellingen aan gelinkt zijn of omdat er nog voorraad is van dit product.')
    }
  }

  useEffect(() => {
    if (delRes && delRes.ok) {
      navigate('/producten')
      refresh()
    } else if (delError) {
      console.error('Fout bij het verwijderen van product', delError)
    }
  })

  return (
    <>
      <button onClick={() => deleteProduct()}><FaTrashAlt fontSize={20} color={'black'} /></button>
    </>
  )
}
