import { useFetch } from '@chainless/chainless-react'
import React, { useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import './Auditlog.css'

const Auditlog = () => {
  const { data: auditlog } = useFetch('/api/auditlog')
  const currentDate = Date.now()
  const twoWeeks = currentDate - 14 * 24 * 60 * 60 * 1000
  const [selectedEntity, setSelectedEntity] = useState('ALL')
  const [startDate, setStartDate] = useState(new Date(twoWeeks))
  const [endDate, setEndDate] = useState(new Date(currentDate))
  const [inputText, setInputText] = useState('')

  const formatTimestamp = (timestampInSeconds) => {
    const timestampInMilliseconds = timestampInSeconds * 1000
    const date = new Date(timestampInMilliseconds)
    return date.toLocaleString('nl-NL', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    })
  }

  const formatDataJson = (dataJson) => {
    const formattedDataJson = dataJson.replace(/[(){}"']/g, '').replace(/,/g, ' ')

    const regex = /(Date:)(\d+\.\d+)/g
    const formattedJsonWithDates = formattedDataJson.replace(regex, (match, datePrefix, timestamp) => {
      const date = new Date(parseFloat(timestamp) * 1000)
      const day = date.getDate()
      const month = date.getMonth() + 1
      const year = date.getFullYear()
      const formattedDate = `${day}-${month}-${year}`
      return `${datePrefix} ${formattedDate}`
    })

    return formattedJsonWithDates
  }

  const handleChange = (range) => {
    const [start, end] = range
    setStartDate(start)
    setEndDate(end)
  }

  const handleEntityChange = (event) => {
    const selectedEntity = event.target.value
    setSelectedEntity(selectedEntity)
  }

  const translateEntity = (entity) => {
    const translationMap = {
      ORDER: 'BESTELLING',
      RESERVATION: 'RESERVERING',
      PRODUCTAMOUNT: 'PRODUCTAANTAL'
    }

    return translationMap[entity] || entity
  }

  const inputHandler = (e) => {
    const lowerCase = e.target.value.toLowerCase()
    setInputText(lowerCase)
  }

  return (
    <div className='logging'>
      <div className='heading'>
        <h1>Auditlog</h1>
        <p>Filteren op datum:</p>
        <DatePicker
          selected={startDate}
          onChange={handleChange}
          startDate={startDate}
          endDate={endDate}
          selectsRange
          className='datepicker'
        />
      </div>
      <div className='loglist'>
        <div className='loglistlegenda'>
          <select name='ENTITY' id='ENTITY' onChange={handleEntityChange} value={selectedEntity}>
            <option value='ALL'>ALLES</option>
            <option value='PRODUCT'>PRODUCT</option>
            <option value='PROJECT'>PROJECT</option>
            <option value='RESERVATION'>RESERVERING</option>
            <option value='ORDER'>BESTELLING</option>
            <option value='PRODUCTAMOUNT'>PRODUCTAANTAL</option>
          </select>
          <input placeholder='Zoeken... ' type='text' onChange={inputHandler} />
        </div>
        {auditlog &&
          auditlog
            .filter((log) => {
              const logTimestamp = new Date(log.logtime * 1000)
              const entityFilter = selectedEntity === 'ALL' || log.entity === selectedEntity
              const dateFilter = logTimestamp >= startDate && logTimestamp <= endDate
              return entityFilter && dateFilter && log.dataJson.toLowerCase().includes(inputText)
            })
            .map((log) => (
              <div className='logitems' key={log.id}>
                <p>{translateEntity(log.entity)}</p>
                <p>{log.actionType}</p>
                <p>{formatTimestamp(log.logtime)}</p>
                <p style={{ width: '40%' }}>{formatDataJson(log.dataJson)}</p>
                <p>{log.username}</p>
              </div>
            ))}
      </div>
    </div>
  )
}

export default Auditlog
