import { useFetch } from '@chainless/chainless-react'
import React from 'react'
import { FaCircle, FaExclamationTriangle } from 'react-icons/fa'
import { useParams } from 'react-router-dom'
import './ProjectDetails.css'
import { BestellingenOnderProject, ReserveringenOnderProjecten } from './Lists'

const ProjectDetails = () => {
  const { projCode } = useParams()
  const { data: projOrders } = useFetch(`/api/orders_of_project?projectCode=${projCode}`)
  const { data: proj, fetchData: refresh } = useFetch(`/api/projects/${projCode}`)

  return (
    <>
      {proj &&
        <>
          <div className='details'>
            <h1>{proj.name}</h1>
            {proj.name !== 'Verlopen reserveringen' &&
              <div className='legenda'>
                <p><FaCircle title='Besteld' style={{ color: '#00a0bf', fontSize: 20 }} /></p>
                <p>In bestelling</p>
                <p><FaCircle title='Gereserveerd' style={{ color: '#485074', fontSize: 20 }} /></p>
                <p>Gereserveerd</p>
                <p><FaExclamationTriangle style={{ color: '#e30303', fontSize: 18 }} /></p>
                <p>Check voorraad</p>
                <p style={{ fontSize: 16, color: 'grey' }}>||</p>
                <p>Originele reservering</p>
              </div>
            }
          </div>
          {proj.reservations &&
            <div className='projectonderdelen'>
              <div className='producten'>
                <h2>Producten</h2>
                <ReserveringenOnderProjecten reservations={proj.reservations} reloadResevations={refresh} projCode={projCode} />
              </div>
            </div>
          }
          {projOrders && projOrders.length !== 0 &&
            <div className='bestellingen'>
              <h2 style={{ color: '#00a0bf', marginTop: 50 }}>Bestellingen</h2>
              <BestellingenOnderProject orders={projOrders} />
            </div>
          }
        </>
      }
    </>
  )
}

export default ProjectDetails
