import { useFetch } from '@chainless/chainless-react'
import React, { useEffect, useState } from 'react'
import { NavLink, Outlet } from 'react-router-dom'
import './Projecten.css'
import { ProjectenLijst } from './Lists'

const Projecten = () => {
  const { data: projectsData } = useFetch('/api/projects')
  const [inputText, setInputText] = useState('')
  const [projecten, setProjecten] = useState([])

  const inputHandler = (e) => {
    const lowerCase = e.target.value.toLowerCase()
    setInputText(lowerCase)
  }

  useEffect(() => {
    if (projectsData) {
      setProjecten(projectsData
        .filter((p) => {
          if (p.name === 'Stock') {
            return false
          } else if (inputText === '') {
            return p
          }
          return p.fullName.toLowerCase().includes(inputText)
        })
        .sort((a, b) => a.fullName.localeCompare(b.fullName))
        .sort((a, b) => (b.reservations || []).length - (a.reservations || []).length)
        .sort((a, b) => b.reservationCount - a.reservationCount)
      )
    }
  }, [projectsData, inputText])

  return (
    <>
      <div className='projectenlijst'>
        <h1>Lopende projecten</h1>
        <div className='upper'>
          <input onChange={inputHandler} placeholder='Zoeken...' type='text' />
        </div>
        {projecten.length &&
          <div className='items'>
            <ProjectenLijst projects={projecten} />
          </div>
        }
        <NavLink to={'/projecten/old_reservations'} className='createButtons'><button>Verlopen reserveringen</button></NavLink>
      </div>
      <div className='projectdetails'>
        <Outlet />
      </div>
    </>
  )
}

export default Projecten
