import { useFetch } from '@chainless/chainless-react'
import React, { useEffect, useState } from 'react'
import { FaTrashAlt } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import './CreateBest.css'
import { AddShortages } from './AddShortages'

export const CreateBest = ({ refresh }) => {
  const navigate = useNavigate()
  const { data: projectsData } = useFetch('/api/projects')
  const { data: productsData } = useFetch('/api/product')
  const { data: postOrderRes, error: postOrderError, fetchData: postOrder } = useFetch('/api/controller_order', { method: 'POST', lateFetch: true })
  const [projects, setProjects] = useState([])
  const [products, setProducts] = useState([])
  const [newProject, setNewProject] = useState('')
  const [newProduct, setNewProduct] = useState('')
  const [number, setNumber] = useState(1)
  const [order, setOrder] = useState({ name: '', projects: [], info: '' })

  useEffect(() => {
    if (projectsData) {
      setProjects(projectsData.sort((a, b) => a.name.localeCompare(b.name)))
    }
  }, [projectsData])

  useEffect(() => {
    if (productsData) {
      setProducts(productsData.sort((a, b) => a.name.localeCompare(b.name)))
    }
  }, [productsData])

  const findOrInsert = (list, predicate, newItem) => {
    const listItem = list.find(predicate)
    if (!listItem) {
      list.push(newItem)
    }
    return listItem || newItem
  }

  const addOrderItem = (selectedProd, selectedProj, amount) => {
    const project = projects.find(proj => proj.fullName === selectedProj)
    const product = products.find(prod => prod.name === selectedProd)
    if (!project) {
      alert('Geselecteerd project niet gevonden!')
      setNewProject('')
      return
    }
    if (!product) {
      alert('Geselecteerd product niet gevonden!')
      setNewProduct('')
      return
    }
    setOrder(order => {
      const newOrder = { ...order }

      const projectFromOrder = findOrInsert(newOrder.projects, orderProject => orderProject.code === project.code, {
        code: project.code, project: project.fullName, products: []
      })

      const productFromOrder = findOrInsert(projectFromOrder.products, orderProduct => orderProduct.id === product.id, {
        id: product.id,
        name: product.name,
        reservationAvailabilityDate: 0
      })
      productFromOrder.amount = parseInt(amount)
      return newOrder
    })
    setNewProduct('')
  }

  const updateOrderProp = (target) => {
    setOrder(order => ({ ...order, [target.name]: target.value }))
  }

  const deleteOrderItem = (prodId, projId) => {
    setOrder(order => ({
      ...order,
      projects: order.projects.map(proj => {
        if (proj.id !== projId) {
          return proj
        } else {
          const newProds = proj.products.filter(prod => prod.id !== prodId)
          if (newProds.length) {
            return { ...proj, products: newProds }
          }
          return undefined
        }
      })
        .filter(proj => !!proj)
    }))
  }

  const submitOrder = async () => {
    if (order.projects !== undefined && order.name !== '') {
      postOrder(JSON.stringify(order))
    } else {
      alert('Vul alle velden in')
    }
  }
  useEffect(() => {
    if (postOrderRes && postOrderRes.id) {
      navigate('/bestellingen')
      refresh()
    } else if (postOrderError) {
      console.error('Fout bij het aanmaken van de bestelling', postOrderError)
    }
  }, [postOrderRes, postOrderError])

  if (projects.length && products.length) {
    return (
      <>
        <h1 style={{ color: '#00a0bf' }}>Bestelling aanmaken</h1>
        <div className='bestInhoud'>
          <section className='bestInhoudItems'>
            <label>Naam bestelling:</label>
            <input
              type="text"
              placeholder='Vul een naam in...'
              required
              maxLength={100}
              value={order.name}
              name='name'
              onChange={(e) => updateOrderProp(e.target)}
            />
            <label>Info bestelling:</label>
            <input
              type="text"
              placeholder='Track & trace link of andere info...'
              required
              height={100}
              value={order.info}
              name='info'
              onChange={(e) => updateOrderProp(e.target)}
            />
            <section className='shoppingCart'>
              <label>Project en product selecteren</label>
              <input list="projects-list" onChange={(e) => setNewProject(e.target.value)} value={newProject} placeholder="Project naam" />
              <datalist id="projects-list">
                {projects.map(proj => (
                  <option key={proj.id}>{proj.fullName}</option>
                ))}
              </datalist>
              <div className='newProduct'>
                <input list="products-list" onChange={(e) => setNewProduct(e.target.value)} value={newProduct} placeholder="Product naam" />
                <datalist id="products-list">
                  {products.map(prod => (
                    <option key={prod.id}>{prod.name}</option>
                  ))}
                </datalist>
                <input style={{ width: '15%', height: 35 }} type='number' min={0} onChange={(e) => setNumber(e.target.value)} value={number} />
              </div>
              <div className='addButton'>
                <button onClick={() => addOrderItem(newProduct, newProject, number)}>Toevoegen</button>
                <AddShortages proj={newProject ? projects.find(p => p.fullName === newProject) : ''} addOrderItem={addOrderItem} />
              </div>
            </section>
            <label>Bestelling:</label>
            <div className='newOrder'>
              {order &&
                order.projects.map(proj => (
                  <div className='orderItem' key={proj.id}>
                    <span style={{ color: 'black' }}>{proj.project}</span>
                    {proj.products.map(prod => (
                      <div className='orderProducts' key={prod.id}>
                        <span style={{ color: '#00a0bf' }}>{prod.name}</span>
                        <div className='orderAmounts'>
                          <span style={{ color: '#00a0bf' }}>{prod.amount}</span>
                          <button onClick={() => deleteOrderItem(prod.id, proj.id)}><FaTrashAlt style={{ marginLeft: 5, fontSize: 15, color: 'grey' }} /></button>
                        </div>
                      </div>
                    ))}
                  </div>
                ))
              }
            </div>
            <div className='addButton'>
              <button onClick={() => submitOrder()}>Aanmaken</button>
            </div>
          </section>
        </div>
      </>
    )
  }
}
