import { useFetch } from '@chainless/chainless-react'
import React, { useEffect } from 'react'
import { FaTrash } from 'react-icons/fa'

export const DeleteReservation = ({ resId, reloadResevations, setselectedReservation }) => {
  const { res: delContrResRes, fetchData: delRes, error: delContrResError } = useFetch(`/api/controller_reservations?res_id=${resId}`, { method: 'DELETE', lateFetch: true })

  const deleteReservation = () => {
    if (window.confirm('Deze reservering verwijderen?')) {
      delRes()
    }
  }

  useEffect(() => {
    if (delContrResRes && delContrResRes.ok) {
      reloadResevations()
      if (setselectedReservation) { setselectedReservation() }
    } else if (delContrResError) {
      console.error('Fout bij het verwijderen van reservering', delContrResError)
    }
  }, [delContrResError, delContrResRes])

  return (
    <button onClick={() => deleteReservation()}><FaTrash style={{ fontSize: 16 }} /></button>
  )
}
